<template>
  <div class="formBody">
    <div class="date">
      <h4>{{ date }}</h4>
      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
      <p>{{$t("product-page.room")}} {{ index + 1 }} : {{ $t("product-page.choose-room") }}</p>
    </div>
    <div class="roombox">
      <div class="roomaddbox">
        <div class="row g-2 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 row-cols-xxl-2">
          <div class="col">
            <div class="form-group">
              <label>{{ $t("product-page.room-type") }}</label>
              <div class="Fromselect">
                <b-form-select
                  v-model="currentTypeCode"
                  :options="optionByRoomTypes"
                  class="form-control"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>{{ $t("product-page.room-class") }}</label>
              <b-form-select
                v-model="currentClassCode"
                :options="optionByRoomClasses"
              ></b-form-select>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>{{ $t("product-page.basis") }}</label>
              <div class="Fromselect">
                <b-form-select
                  v-model="currentBasis"
                  :options="optionBasis"
                ></b-form-select>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>{{ $t('product-page.babies') }}</label>
              <div class="Fromselect">
                <b-form-select
                  v-model="countOfBaby"
                  :options="babies"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue';
import TemplatePriceOne from './TemplatePriceOne';

export default {
  mixins: [TemplatePriceOne],
  components: {
    BFormSelect,
    // BFormSelectOption,
    // BSpinner,
  },
};
</script>

<style scoped>
.product-filter.card .card-header {
    background-color: transparent;
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    padding: 8px 15px;
}
.product-filter.card .card-body{
    padding: 15px;
}
.product-filter.card .card-body .date{
    margin: 0 0 8px;
}
.product-filter.card .card-body .date h4{
    position: relative;
    padding-right: 22px;
    color: #000000;
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 5px;
}
.product-filter.card .card-body .date h4::before{
    content: "\f073";
    position: absolute;
    top: 0;
    right: 0;
    font-family: FontAwesome;
    color: #23BEDC;
}
.product-filter.card .card-body .date p{
    color: #000000;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0;
}
.product-filter.card .card-body p strong{
    font-weight: 600;
    color: #1993C8;
}
.product-filter.card .card-body .form-group label {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 6px;
}
.product-filter.card .card-body .form-group .Fromselect{
    position: relative;
}
.product-filter.card .card-body .form-group .Fromselect select.form-control {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    height: 42px;
    border-radius: 5px;
}
.product-filter.card .card-body .form-group .Fromselect select.form-control:focus{
    box-shadow: none;
}
.product-filter.card .card-body .formBody {
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 15px;
    position: relative;
}
.product-filter.card .card-body .formBody:last-child{
    margin-bottom: 0;
}
.product-filter.card .card-body .formBody .date button.js-del-row{
  left: 10px;
  top: 5px;
}
</style>
