import { mapGetters } from 'vuex';

export default {
  name: 'TemplatePriceOnce',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    hotels: {
      type: Array,
      default: () => [],
    },
    supplier: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      optionByRoomTypes: [],
      optionsBasis: [],
      currentClassCode: '',
      currentTypeCode: '',
      currentBasis: '',
      countOfBaby: 0,
      babies: [
        { value: 0, text: this.$t('product-page.babies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ],
      roomClassName: '',
      currentPrice: 0,
      oldPrice: 0,
      priceLoading: false,
      query: this.$route.query,
      manualDiscount: +this.$route.query.manualDiscount || 0,
      category: this.$route.query.category && this.$route.query.category !== 'null' ? this.$route.query.category : undefined,

      availableRooms: 0,

      // minPriceHotel: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      isMemberLogin: 'IS_AUTHORIZED',
      memberDiscountPercent: 'GET_DISCOUNT_PERCENT',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
    }),
    optionByRoomClasses() {
      if (this.hotels.length === 0) {
        return [];
      }
      const roomCodes = [
        ...new Set(this.hotels.map((item) => item.roomCode)),
      ];
      const options = roomCodes.map((elm) => ({ value: elm, text: this.hotels.find((elem) => elem.roomCode === elm).roomName }));
      this.setRoomClassCode(options);
      return options;
    },
    optionBasis() {
      if (this.hotels.length === 0) {
        return [];
      }
      const { priceList } = this.query;
      const basis = (priceList && priceList !== 'null') ? [
        ...new Set(this.hotels.filter((elm) => elm.priceList === priceList).map((elm) => elm.basis)),
      ] : [
        ...new Set(this.hotels.map((elm) => elm.basis)),
      ];
      const options = basis.map((elm) => ({ value: elm, text: this.$t(`basis-name.${elm.toLowerCase().trim()}`) }));

      this.setBasis(options);
      return options;
    },
    personString() {
      const adult = Number((this.currentTypeCode.split(',')[0]));
      const child = Number(this.currentTypeCode.split(',')[1]);
      const infant = Number(this.countOfBaby);
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    roomTypeArray() {
      return [
        ['2,0', 'pair'],
        ['2,1', 'couple-child'],
        ['2,2', 'couple-2-child'],
        ['2,3', 'couple-3-child'],
        ['2,4', 'couple-4-child'],
        ['1,0', 'single'],
        ['1,1', 'single-child'],
        ['1,2', 'single-2-child'],
        ['3,0', 'three-adult'],
        ['3,1', 'three-adult-child'],
        ['3,2', 'three-adult-2-child'],
        ['4,0', 'four-adult'],
        ['5,0', 'five-adult'],
        ['6,0', 'six-adult'],
        ['7,0', 'seven-adult'],
      ];
    },
    showBabyNotice() {
      return this.countOfBaby > 0 && this.hotels.length > 0 && this.hotels[0].supplierCode === 'ATLANTIS';
    },
  },
  watch: {
    currentTypeCode() {
      this.fetchHotels();
    },
    countOfBaby() {
      this.fetchHotels();
    },
    currentClassCode() {
      this.roomClassName = this.getRoomClassName();
      this.updateItem();
    },
    currentBasis() {
      this.updateItem();
    },
    memberDiscountPercent() {
      this.updateItem();
    },
    manualDiscount() {
      this.updateItem();
    },
    priceLoading() {
      this.$emit('setPriceLoading', this.priceLoading);
    },
    lang() {
      this.getRoomTypeOptions();
      // this.updateItem();
      this.babies = [
        { value: 0, text: this.$t('product-page.babies') },
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 },
      ];
    },
    hotels() {
      if (this.hotels.length === 0) {
        this.$bvModal.msgBoxOk(
          this.$t('product-page.no-hotel-message'),
          {
            title: this.$t('product-page.expire-title'),
            bodyClass: 'text-center',
            okVariant: 'success',
            okTitle: this.$t('product-page.ok'),
            headerClass: 'p-2 border-bottom-0',
            footerClass: 'p-2 border-top-0',
            centered: true,
          },
        );
      }
      this.updateItem();
    },
  },
  beforeMount() {
    this.getRoomTypeOptions();
    this.countOfBaby = Number(this.query.infant);
    this.currentTypeCode = `${this.query.adult},${this.query.child}`;
  },
  methods: {
    async fetchHotels() {
      const adult = this.currentTypeCode.split(',')[0], child = this.currentTypeCode.split(',')[1];
      const body = {
        city: this.query.city,
        hotelCode: String(this.query.hotelCode),
        dealCategoryId: this.query.dealCategoryId,
        checkIn: this.query.checkIn,
        checkOut: this.query.checkOut,
        adult: Number(adult),
        child: Number(child),
        infant: Number(this.countOfBaby),
        lang: this.query.lang,
        includeFlight: null,
        suppliers: (this.query.suppliers) ? [this.query.suppliers] : [],
        returnLog: null,
      };

      this.priceLoading = true;
      const packages = await this.$store.dispatch('FETCH_HOTELS_BY_OCCUPATION', body);
      this.$emit('changedRoomType', this.index, packages);
      this.priceLoading = false;
    },
    setRoomClassCode(option) {
      // this.minPriceHotel = this.getMinHotel();
      // const inx = option.findIndex((elm) => elm.value === this.minPriceHotel.roomCode);
      let inx = option.findIndex((elm) => elm.value === this.query.roomCode);
      if (inx === -1) inx = 0;
      this.currentClassCode = option?.[inx]?.value;
    },
    setBasis(option) {
      // this.minPriceHotel = this.getMinHotel();
      // const inx = option.findIndex((elm) => elm.value === this.minPriceHotel.basis);
      const inx = option.findIndex((elm) => elm.value === this.query.basisCode);
      this.currentBasis = option?.[inx]?.value || '';
    },
    getRoomTypeOptions() {
      this.optionByRoomTypes = this.roomTypeArray.map((item) => ({ value: item[0], text: this.$t(`room-type-list.${item[1]}`) }));
    },
    getRoomClassName() {
      return this.optionByRoomClasses.find((elm) => (elm.value === this.currentClassCode))?.text || '';
    },
    remove() {
      this.$emit('closed', this.index);
    },
    async updateItem() {
      const priceList = this.query.priceList || null;
      let hotel = null;
      if (this.category) {
        hotel = this.hotels.find((elm) => (elm.roomCode === this.currentClassCode
        && elm.basis === this.currentBasis && (!elm.priceList || elm.priceList === priceList)
        && elm.category === (this.category || null)));
      } else {
        hotel = this.hotels.find((elm) => (elm.roomCode === this.currentClassCode
        && elm.basis === this.currentBasis && (!elm.priceList || elm.priceList === priceList)));
      }
      const oPrice = hotel?.totalAfterDiscounted || NaN;
      const isAtlantis = hotel?.matchingDiscInfo?.atlantisDiscounted || false;
      const isCreditCardRemarkHotel = !!hotel?.matchingDiscInfo?.creditCardRemark || false;
      const price = (isAtlantis || isCreditCardRemarkHotel) ? oPrice : oPrice * (1 - this.memberDiscountPercent / 100);
      let roomPrice = (this.isMemberLogin) ? Math.round(price) : Math.round(oPrice);
      if (this.isOdysseySite) {
        roomPrice = Math.round((hotel?.total || 0) * (1 - this.manualDiscount / 100));
      }

      this.availableRooms = hotel?.avail || 0;
      const occupString = this.roomTypeArray.find((item) => (item[0] === this.currentTypeCode))[1];

      const updateData = {
        index: this.index,
        searchGuid: hotel?.searchGuid,
        roomRateGuid: hotel?.roomRateGuid,
        roomClass: this.currentClassCode,
        roomClassName: this.roomClassName,
        roomBasis: this.currentBasis,
        roomBasisName: this.optionBasis.find((el) => el.value === this.currentBasis)?.text,
        adult: this.currentTypeCode.split(',')[0],
        child: this.currentTypeCode.split(',')[1],
        infant: this.countOfBaby,
        price: roomPrice, // hotel?.totalAfterDiscounted || NaN,
        oldPrice: hotel?.total || NaN,
        token: hotel?.token || NaN,
        discountPercent: hotel?.discountPercent || NaN,
        priceList: hotel?.priceList || NaN,
        roomTypeName: this.$t(`room-type-list.${occupString}`),
        roomOccupCode: hotel?.roomOccupCode || this.currentTypeCode,
        roomOccupString: occupString,
        category: hotel?.category || null,
        validation: !!hotel,
        net: hotel?.net || 0,
      };

      this.currentPrice = (hotel) ? updateData.price : NaN;
      // eslint-disable-next-line no-nested-ternary
      this.oldPrice = (!hotel) ? NaN : (updateData.oldPrice === updateData.price) ? (updateData.oldPrice * 1.1).toFixed(0) : updateData.oldPrice;

      this.$emit('update', updateData);
    },
    // getMinHotel() {
    //   return this.hotels.reduce((prev, curr) => (prev.totalAfterDiscounted < curr.totalAfterDiscounted ? prev : curr));
    // },
  },
};
